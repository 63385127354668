import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { PaginationStateBase, PaginationStateModel } from '../../../burns-ui-framework/shared/store/shared/pagination-state-base';

import { EmployeesService } from '../../services/employees.service';

import { EmployeeListGetFilter, EmployeeListGetFilterData } from '../../models/filters/employee-list-get-filter.model';
import { EmployeeListItem } from '../../models/business/employee/employee-list-item.model';
import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';
import { PaginationRequest } from '../../../burns-ui-framework/shared/models/common/pagination-request.model';

import { EmployeesArchivedAction, EmployeesArchivedFailAction, EmployeesArchivedResetAction, EmployeesArchivedSetFilterAction, EmployeesArchivedSuccessAction } from './employees-archived.actions';

import { CompareUtils } from '../../../burns-ui-framework/shared/utils/compare-utils';
import { ExgParamsConfig } from '../../exg-params.config';

export interface EmployeesArchivedStateModel extends PaginationStateModel<EmployeeListItem> {
    uids: string[];
    entities: { [uid: string]: EmployeeListItem };
    retrieved: boolean;
    allItemsLoaded: boolean;
    index: number;
    pending: boolean;
    error: ErrorObject;
    filter: EmployeeListGetFilterData;
    total: number;
}

@State<EmployeesArchivedStateModel>({
    name: 'employeesArchived',
    defaults: { pending: false, uids: [], entities: {}, retrieved: false, error: null, allItemsLoaded: false, index: 0, total: 0, filter: {} }
})
@Injectable()
export class EmployeesArchivedState extends PaginationStateBase {
    constructor(private employeesService: EmployeesService) {
        super();
    }

    @Action([EmployeesArchivedAction]) employeesGet(ctx: StateContext<EmployeesArchivedStateModel>, action: EmployeesArchivedAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, error: null });

        const filter = new EmployeeListGetFilter({ pageIndex: state.index, pageSize: ExgParamsConfig.pagination.employeesAllListPageSize, filterData: state.filter });
        if (!this.applyFilterPagination(action.payload, state, filter)) {
            ctx.setState({ ...state, pending: false });
            return Promise.resolve();
        }

        return this.employeesService.getEmployees(filter)
            .then(resp => setTimeout(() => ctx.dispatch(new EmployeesArchivedSuccessAction({ list: resp, pagination: action.payload })), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new EmployeesArchivedFailAction(err)), 0));
    }

    @Action(EmployeesArchivedSuccessAction) employeesGetSuccess(ctx: StateContext<EmployeesArchivedStateModel>, action: EmployeesArchivedSuccessAction) {
        const state = ctx.getState();
        const { uids, entities, allItemsLoaded, index, total } = this.handlePaginationResults(state, action.payload.list, action.payload.pagination, ExgParamsConfig.pagination.employeesAllListPageSize, true);
        ctx.setState({ ...state, pending: false, uids, entities, total, retrieved: true, error: null, allItemsLoaded, index });
    }

    @Action(EmployeesArchivedFailAction) employeesGetFail(ctx: StateContext<EmployeesArchivedStateModel>, action: EmployeesArchivedFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(EmployeesArchivedResetAction) employeesGetReset(ctx: StateContext<EmployeesArchivedStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, uids: [], entities: {}, retrieved: false, error: null, allItemsLoaded: false, index: 0, filter: {} });
    }

    @Action(EmployeesArchivedSetFilterAction) employeesSetFilter(ctx: StateContext<EmployeesArchivedStateModel>, action: EmployeesArchivedSetFilterAction) {
        const state = ctx.getState();
        const filter = { ...state.filter, ...action.payload };
        ctx.setState({ ...state, filter, retrieved: false, index: 0 });
        setTimeout(() => ctx.dispatch(new EmployeesArchivedAction(PaginationRequest.ReloadCurrentList)), 0);
    }
}
