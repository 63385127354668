import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { DrillingRigsService } from '../../services/drilling-rigs.service';

import { DrillingRig } from '../../models/business/drilling-rig/drilling-rig.model';
import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';

import { DrillingRigUpdateArchiveSuccessAction } from './drilling-rig-update-archive.actions';
import { DrillingRigAction, DrillingRigFailAction, DrillingRigResetAction, DrillingRigSuccessAction } from './drilling-rig.actions';

export interface DrillingRigStateModel {
    entity: DrillingRig;
    pending: boolean;
    error: ErrorObject;
}

@State<DrillingRigStateModel>({
    name: 'drillingRig',
    defaults: { pending: false, entity: null, error: null }
})
@Injectable()
export class DrillingRigState {
    constructor(private drillingRigsService: DrillingRigsService) {}


    @Action(DrillingRigAction) drillingRigGet(ctx: StateContext<DrillingRigStateModel>, action: DrillingRigAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, error: null });

        return this.drillingRigsService.getDrillingRig(action.payload.uid)
            .then(resp => setTimeout(() => ctx.dispatch(new DrillingRigSuccessAction(resp)), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new DrillingRigFailAction(err)), 0));
    }

    @Action(DrillingRigSuccessAction) drillingRigGetSuccess(ctx: StateContext<DrillingRigStateModel>, action: DrillingRigSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, entity: action.payload, error: null });
    }

    @Action(DrillingRigFailAction) drillingRigGetFail(ctx: StateContext<DrillingRigStateModel>, action: DrillingRigFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(DrillingRigResetAction) drillingRigGetReset(ctx: StateContext<DrillingRigStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, entity: null, error: null });
    }

    @Action(DrillingRigUpdateArchiveSuccessAction) drillingRigUpdateSuccess(ctx: StateContext<DrillingRigStateModel>, action: DrillingRigUpdateArchiveSuccessAction) {
        const state = ctx.getState();

        if (action.payload.uid === state.entity.uid) {
            const entity = { ...state.entity, ...action.payload.request };
            ctx.setState({ ...state, pending: false, entity, error: null });
        }
    }
}
