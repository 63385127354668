import { EmployeeListGetFilterData } from '../../models/filters/employee-list-get-filter.model';
import { EmployeeListItem } from '../../models/business/employee/employee-list-item.model';
import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';
import { PaginationRequest } from '../../../burns-ui-framework/shared/models/common/pagination-request.model';
import { PaginationResult } from '../../../burns-ui-framework/shared/models/common/pagination-result.model';

export class EmployeesArchivedAction {
    static readonly type = '[EmployeesArchived Page] GetEmployeesArchived';

    constructor(public payload: PaginationRequest) { }
}

export class EmployeesArchivedSuccessAction {
    static readonly type = '[EmployeesArchived API] GetEmployeesArchived Success';

    constructor(public payload: { list: PaginationResult<EmployeeListItem>, pagination: PaginationRequest }) { }
}

export class EmployeesArchivedFailAction {
    static readonly type = '[EmployeesArchived API] GetEmployeesArchived Fail';

    constructor(public payload: ErrorObject) { }
}

export class EmployeesArchivedResetAction {
    static readonly type = '[EmployeesArchived Page] GetEmployeesArchived Reset';
}

export class EmployeesArchivedSetFilterAction {
    static readonly type = '[EmployeesArchived API] SetFilter';

    constructor(public payload: EmployeeListGetFilterData) { }
}
