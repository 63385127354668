import { Injectable } from '@angular/core';

import { BaseSingletonService } from '../../burns-ui-framework/shared/services/common/base-singleton.service';
import { ExgTranslateService } from '../../burns-ui-framework/shared/services/common/exg-translate.service';
import { HttpService } from '../../burns-ui-framework/shared/services/common/http.service';

import { ArchiveDrillingRigRequest } from '../models/business/drilling-rig/archive-drilling-rig-request.model';
import { DrillingRigCreateUpdateRequest } from '../models/business/drilling-rig/drilling-rig-create-update-request.model';
import { DrillingRigListItem } from '../models/business/drilling-rig/drilling-rig-list-item.model';
import { DrillingRigListWithTransfersGetFilter } from '../models/filters/drilling-rig-list-with-transfers-get-filter.model';
import { DrillingRigWithTransfers } from '../models/business/drilling-rig/drilling-rig-with-transfers.model';
import { DrillingRigWorkbookGetFilter } from '../models/filters/drilling-rig-workbook-get-filter.model';
import { DrillingRig } from '../models/business/drilling-rig/drilling-rig.model';
import { DrillingRigsListGetFilter } from '../models/filters/drilling-rigs-list-get-filter.model';
import { PaginationResult } from '../../burns-ui-framework/shared/models/common/pagination-result.model';
import { TransportDrillingRigsItem } from '../models/business/transport-drilling-rigs/transport-drilling-rigs-item.model';

import { DateUtils } from '../../burns-ui-framework/shared/utils/date-utils';
import { GuidUtils } from '../../burns-ui-framework/shared/utils/guid-utils';
import { String } from '../../burns-ui-framework/shared/utils/string';

@Injectable({
    providedIn: 'root'
})
export class DrillingRigsService extends BaseSingletonService {

    private settings: {
        service: {
            getDrillingRig: string;
            getDrillingRigs: string;
            createDrillingRig: string;
            updateDrillingRig: string;
            deleteDrillingRig: string;
            getDrillingRigSchedule: string;
            getWorkbook: string;
            getDrillingRigsWithTransfers: string;
            archiveDrillingRig: string;
        }
    };

    constructor(private http: HttpService, private translate: ExgTranslateService) {
        super('DrillingRigsService');
        this.settings = {
            service: {
                getDrillingRig: '/drilling/v1/drilling-rigs/{0}',
                getDrillingRigs: '/drilling/v1/drilling-rigs?term={0}&isArchived={1}&sortField={2}&sortOrder={3}&pageIndex={4}&pageSize={5}',
                createDrillingRig: '/drilling/v1/drilling-rigs',
                updateDrillingRig: '/drilling/v1/drilling-rigs/{0}',
                deleteDrillingRig: '/drilling/v1/drilling-rigs/{0}',
                getDrillingRigSchedule: '/drilling/v1/drilling-rigs/schedule?term={0}&termField={1}&sortField={2}&sortOrder={3}&pageIndex={4}&pageSize={5}&dateFrom={6}&dateTo={7}',
                getWorkbook: '/drilling/v1/drilling-rigs/breakdowns-workbook?workingAreaUid={0}&DateFrom={1}&DateTo={2}',
                getDrillingRigsWithTransfers: '/drilling/v1/drilling-rigs/with-transfers?workingAreaUid={0}&dateFrom={1}&dateTo={2}',
                archiveDrillingRig: '/drilling/v1/drilling-rigs/{0}/archive'
            }
        };
    }

    public async getDrillingRig(uid: string): Promise<DrillingRig> {
        return this.http.get<{ data: DrillingRig }>(String.format(this.settings.service.getDrillingRig, uid))
            .then(res => res.data);
    }

    public async getTransportDrillingRigs(filter: DrillingRigsListGetFilter): Promise<PaginationResult<TransportDrillingRigsItem>> {
        return this.http.get<PaginationResult<TransportDrillingRigsItem>>(String.format(this.settings.service.getDrillingRigSchedule, filter.term, filter.termField, filter.sortField, filter.sortOrder, filter.pageIndex, filter.pageSize, filter.dateFrom, filter.dateTo))
            .then((res) => {
                res.pageSize = filter.pageSize;
                res.pageIndex = filter.pageIndex;
                res.list = res.list.map(l => ({ ...l, uid: GuidUtils.newGuid }));
                return res;
            });
    }

    public async getDrillingRigsWithTransfers(filter: DrillingRigListWithTransfersGetFilter): Promise<DrillingRigWithTransfers[]> {
        return this.http.get<{ list: DrillingRigWithTransfers[] }>(String.format(this.settings.service.getDrillingRigsWithTransfers, filter.workingAreaUid, filter.dateFrom, filter.dateTo))
            .then((res) => res.list);
    }

    public async getDrillingRigList(filter: DrillingRigsListGetFilter): Promise<PaginationResult<DrillingRigListItem>> {
        return this.http.get<PaginationResult<DrillingRigListItem>>(String.format(this.settings.service.getDrillingRigs, filter.term, filter.isArchived, filter.sortField, filter.sortOrder, filter.pageIndex, filter.pageSize))
            .then((res) => {
                res.pageSize = filter.pageSize;
                res.pageIndex = filter.pageIndex;
                return res;
            });
    }

    public async createDrillingRig(request: DrillingRigCreateUpdateRequest): Promise<{ uid: string }> {
        return this.http.post<{ data: {uid: string} }>(String.format(this.settings.service.createDrillingRig), request)
            .then(res => ({ uid: res.data.uid }));
    }

    public async updateDrillingRig(uid: string, request: DrillingRigCreateUpdateRequest): Promise<boolean> {
        return this.http.put(String.format(this.settings.service.updateDrillingRig, uid), request);
    }

    public async deleteDrillingRig(uid: string): Promise<boolean> {
        return this.http.delete(String.format(this.settings.service.deleteDrillingRig, uid));
    }

    public async getWorkbook(filter: DrillingRigWorkbookGetFilter): Promise<Blob> {
        const workbookName = `Отчет по буровым установкам - ${filter.workingArea ? filter.workingArea?.title : ''} - ${ DateUtils.formatEpocToString(filter.dateFrom, this.translate.getCurrentLang(), 'MMMM yy')}.xlsx`;
        return this.http.download(String.format(this.settings.service.getWorkbook, filter.workingArea?.uid, filter.dateFrom, filter.dateTo), workbookName);
    }

    public async archiveDrillingRig(uid: string, request: ArchiveDrillingRigRequest): Promise<boolean> {
        return this.http.put(String.format(this.settings.service.archiveDrillingRig, uid), request);
    }
}
