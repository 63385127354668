export class RoutingConfig {
    public static readonly routes = {
        home: {
            route: 'home',
            fullUrl: '/home'
        },
        analytics: {
            route: 'analytics',
            fullUrl: '/analytics',
            details: {
                route: 'details',
                fullUrl: '/analytics/details',
                drillingMap: {
                    route: 'drilling-map',
                    fullUrl: '/analytics/details/drilling-map',
                },
                lossOfProfit: {
                    route: 'loss-of-profit',
                    fullUrl: '/analytics/details/loss-of-profit'
                },
                drillSpeedChangeDynamic: {
                    route: 'drill-speed-change-dynamic',
                    fullUrl: '/analytics/details/drill-speed-change-dynamic'
                },
                activityBalance: {
                    route: 'activity-balance',
                    fullUrl: '/analytics/details/activity-balance'
                },
                activityBalanceList: {
                    route: 'activity-balance-list',
                    fullUrl: '/analytics/details/activity-balance-list'
                },
                productiveBalance: {
                    route: 'productive-balance',
                    fullUrl: '/analytics/details/productive-balance'
                },
                drillingRigRepairBalance: {
                    route: 'drilling-rig-repair-balance',
                    fullUrl: '/analytics/details/drilling-rig-repair-balance'
                },
                causedByCustomerStoppageBalance: {
                    route: 'caused-by-customer-stoppage-balance',
                    fullUrl: '/analytics/details/caused-by-customer-stoppage-balance'
                },
                causedByContractorStoppageBalance: {
                    route: 'caused-by-contractor-stoppage-balance',
                    fullUrl: '/analytics/details/caused-by-contractor-stoppage-balance'
                }
            }
        },
        drillingRig: {
            route: 'drilling-rig',
            fullUrl: '/drilling-rig',
            edit: {
                route: 'edit',
                fullUrl: '/drilling-rig/edit',
            },
            table: {
                route: 'table',
                fullUrl: '/drilling-rig/table'
            },
            schedule: {
                route: 'schedule',
                fullUrl: '/drilling-rig/schedule'
            },
            archive: {
                route: 'archive',
                fullUrl: '/drilling-rig/archive'
            },
        },
        drillingRigTransfers: {
            route: 'transfers',
            fullUrl: '/drilling-rig/edit/:id/transfers',
            edit: {
                route: 'edit',
            }
        },
        drillingRigBreakdowns: {
            route: 'breakdowns',
            fullUrl: '/drilling-rig/edit/:id/breakdowns',
            edit: {
                route: 'edit',
            }
        },
        auth: {
            route: 'auth',
            fullUrl: '/auth',
            registration: {
                route: 'registration',
                fullUrl: '/auth/registration'
            },
            resetPassword: {
                route: 'reset-password',
                fullUrl: '/auth/reset-password'
            }
        },
        companies: {
            route: 'companies',
            fullUrl: '/companies',
            table: {
                route: 'table',
                fullUrl: '/companies/table'
            },
            map: {
                route: 'map',
                fullUrl: '/companies/map'
            },
            edit: {
                route: 'edit',
                fullUrl: '/companies/edit'
            }
        },
        contract: {
            route: 'contracts'
        },
        objects: {
            route: 'objects',
            fullUrl: '/objects',
            edit: {
                route: 'edit',
                fullUrl: '/objects/edit',
            }
        },
        personnel: {
            route: 'personnel',
            fullUrl: '/personnel',
            table: {
                route: 'table',
                fullUrl: '/personnel/table'
            },
            schedule: {
                route: 'schedule',
                fullUrl: '/personnel/schedule'
            },
            archive: {
                route: 'archive',
                fullUrl: '/personnel/archive'
            },
            edit: {
                route: 'edit',
                fullUrl: '/personnel/edit',
            }
        },
        personnelSchedule: {
            route: 'schedule',
        },
        personnelRoutes: {
            route: 'routes',
            edit: {
                route: 'edit',
            }
        },
        phone: {
            route: 'phone',
            fullUrl: '/phone'
        },
        transport: {
            route: 'transport',
            fullUrl: '/transport',
            table: {
                route: 'table',
                fullUrl: '/transport/table'
            },
            workReport: {
                route: 'work-report',
                fullUrl: '/transport/work-report'
            },
            edit: {
                route: 'edit',
                fullUrl: '/transport/edit',
            }
        },
        transportTransfers: {
            route: 'transfers',
            fullUrl: '/transport/edit/:id/transfers',
            edit: {
                route: 'edit',
            }
        },
        transportBreakdowns: {
            route: 'breakdowns',
            fullUrl: '/transport/edit/:id/breakdowns',
            edit: {
                route: 'edit',
            }
        },
        version: {
            route: 'version',
            fullUrl: '/version',
            icons: {
                route: 'icons',
                fullUrl: '/version/icons'
            }
        },
        errors: {
            route: 'errors',
            fullUrl: '/errors'
        },
        settings: {
            route: 'settings',
            fullUrl: '/settings',
            workTypes: {
                route: 'work-types',
                fullUrl: '/settings/work-types',
            },
            drillingDiameters: {
                route: 'drilling-diameters',
                fullUrl: '/settings/drilling-diameters',
            },
            casingTypes: {
                route: 'casing-types',
                fullUrl: '/settings/casing-types',
            },
            activities: {
                route: 'activities',
                fullUrl: '/settings/activities',
            },
            vehicleCategories: {
                route: 'vehicle-categories',
                fullUrl: '/settings/vehicle-categories'
            },
            vehicleTypes: {
                route: 'vehicle-types',
                fullUrl: '/settings/vehicle-types'
            },
            jobPositions: {
                route: 'job-positions',
                fullUrl: '/settings/job-positions'
            }
        }
    };

    public static readonly dynamicRoutes = {
        personnelRoutes: {
            baseUrl: (personelUid: string) => [RoutingConfig.routes.personnel.edit.fullUrl, personelUid, RoutingConfig.routes.personnelRoutes.route],
            editUrl: (personelUid: string, routeUid: string) => [RoutingConfig.routes.personnel.edit.fullUrl, personelUid, RoutingConfig.routes.personnelRoutes.route, RoutingConfig.routes.personnelRoutes.edit.route, routeUid],
        },
        personnelSchedule: {
            baseUrl: (personelUid: string) => [RoutingConfig.routes.personnel.edit.fullUrl, personelUid, RoutingConfig.routes.personnelSchedule.route]
        },
        companyContractRoutes: {
            baseUrl: (companyUid: string) => [RoutingConfig.routes.companies.edit.fullUrl, companyUid, RoutingConfig.routes.contract.route],
            editUrl: (companyUid: string, contractUid: string) => [RoutingConfig.routes.companies.edit.fullUrl, companyUid, RoutingConfig.routes.contract.route, contractUid],
        }
    };
}
