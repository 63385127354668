import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';
import { ShiftType } from '../../models/business/shifts/shift-type.enum';
import { Shift } from '../../models/business/shifts/shift.model';

export class ShiftAction {
    static readonly type = '[Shift Page] GetShift';

    constructor(public payload: { date: number, shiftType: ShiftType, workingAreaUid: string, drillingRigUid: string, contractorCompanyUid: string }) { }
}

export class ShiftSuccessAction {
    static readonly type = '[Shift API] GetShift Success';

    constructor(public payload: Shift) { }
}

export class ShiftFailAction {
    static readonly type = '[Shift API] GetShift Fail';

    constructor(public payload: ErrorObject) { }
}

export class ShiftResetAction {
    static readonly type = '[Shift Page] GetShift Reset';
}
