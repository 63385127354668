import { ChangeDetectionStrategy, Component, Injector, OnDestroy, OnInit } from '@angular/core';

import { BehaviorSubject, combineLatest, Subject, takeUntil } from 'rxjs';

import { DrillingRigSandbox } from '../../../drilling-rig/shared/drilling-rig.sandbox';
import { PersonnelSandbox } from '../../../personnel/shared/personnel.sandbox';

import { ExgTranslateService } from '../../../burns-ui-framework/shared/services/common/exg-translate.service';

import { DialogResult } from '../../../burns-ui-framework/shared/components/common/exg-dialog/shared/dialog-result.model';
import { ExgDialogButton } from '../../../burns-ui-framework/shared/components/common/exg-dialog/shared/exg-dialog-button.model';
import { ExgDialogResultEvent } from '../../../burns-ui-framework/shared/components/common/exg-dialog/shared/exg-dialog-result-event.model';
import { IExgDialogable } from '../../../burns-ui-framework/shared/components/common/exg-dialog/shared/exg-dialogable.interface';
import { RbcExportSelectionType } from './rbc-export-selection-type.model';
import { Group } from '../../models/business/user/user-group.enum';
import { WorkingAreaListItem } from '../../models/business/working-area/working-area-list-item.model';

import { DateUtils } from '../../../burns-ui-framework/shared/utils/date-utils';

import { ExgParamsConfig } from '../../exg-params.config';

@Component({
    selector: 'rbc-export',
    templateUrl: './rbc-export.component.html',
    styleUrls: ['./rbc-export.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RbcExportComponent implements IExgDialogable, OnInit, OnDestroy {
    public workingAreas$ = this.drillingRigSandbox.workingAreasSearch$;
    public employee$ = this.personnelSandbox.selfEmployee$;
    public title: string;
    public selectionType: RbcExportSelectionType;
    public selectionTypes = RbcExportSelectionType;
    public reportType: number;

    public isUser = false;

    public minCalendarDate = ExgParamsConfig.rbcSettings.minCalendarDate;
    public years$ = new BehaviorSubject([]);
    public selectedYear: { name: string } = null;
    public maxDate = DateUtils.convertStringToEpoc(DateUtils.currentDateTime);

    public workingArea: WorkingAreaListItem;
    public isWorkingAreaRequired = true;
    public ignoreWorkingArea = false;

    public dateFrom = DateUtils.convertStringToEpoc(DateUtils.startOf(DateUtils.currentDateTime, 'month'));
    public dateTo = DateUtils.convertStringToEpoc(DateUtils.endOf(DateUtils.currentDateTime, 'month'));

    private proceedClose: (_: ExgDialogResultEvent) => void;

    private unsubscribe$ = new Subject();

    constructor(
        private drillingRigSandbox: DrillingRigSandbox,
        private personnelSandbox: PersonnelSandbox,
        private translate: ExgTranslateService,
        private readonly injector: Injector
    ) {
        this.title = this.injector.get('title');
        this.selectionType = this.injector.get('selectionType', RbcExportSelectionType.Month);
        this.isWorkingAreaRequired = this.injector.get('workingAreaRequired', this.isWorkingAreaRequired);
        this.ignoreWorkingArea = this.injector.get('ignoreWorkingArea', this.ignoreWorkingArea);
        this.reportType = this.injector.get('reportType', null);
        this.drillingRigSandbox.dispatchWorkingAreaSearch({ sortField: 'Title' });
        this.checkIfSelectionTypeIsYear();
    }

    public ngOnInit(): void {
        combineLatest([this.employee$, this.workingAreas$]).pipe(takeUntil(this.unsubscribe$)).subscribe(([employee, workingAreas]) => {
            if (employee.group === Group.User) {
                this.isUser = true;
                this.workingArea = workingAreas.find(area => area.uid === employee.workingArea.uid);
            }
        });
    }

    public ngOnDestroy() {
        this.unsubscribe$.next(true);
        this.unsubscribe$.complete();
    }

    public exgDialogClose(_: ExgDialogButton): any {
        /** no need */
    }

    public workingAreaDisplayValueFunction(item: WorkingAreaListItem) {
        return item?.title;
    }

    public onWorkingAreaChanged($event: WorkingAreaListItem) {
        this.workingArea = $event;
    }

    public onWorkingAreaSearch($event) {
        this.drillingRigSandbox.dispatchWorkingAreaSearch({ term: $event, sortField: 'Title' });
    }

    public onYearChanged($event): void {
        const year = $event?.name + '-12-31';
        this.dateFrom = DateUtils.convertStringToEpoc(DateUtils.startOf(year, 'year'));
        this.dateTo = DateUtils.convertStringToEpoc(DateUtils.endOf(year, 'year'));
    }

    public onDateChanged($event: number) {
        if ($event) {
            this.dateFrom = DateUtils.convertStringToEpoc(DateUtils.startOf(DateUtils.convertEpocToString($event), 'month'));
            this.dateTo = DateUtils.convertStringToEpoc(DateUtils.endOf(DateUtils.convertEpocToString($event), 'month'));
        } else {
            this.dateFrom = null;
            this.dateTo = null;
        }
    }

    public onStartDateChanged(date: number): void {
        this.dateFrom = date;
    }

    public onEndDateChanged(date: number): void {
        this.dateTo = date;
    }

    public onExportClick() {
        this.proceedClose({
            dialogResult: DialogResult.Ok,
            dataFromComponent: { workingArea: this.workingArea, dateFrom: this.dateFrom, dateTo: this.dateTo, reportType: this.reportType },
        });
    }

    public onCancelClick() {
        this.proceedClose({ dialogResult: DialogResult.Cancel });
    }

    public registerOnDialogClose(fn: (_: ExgDialogResultEvent) => void): void {
        this.proceedClose = fn;
    }

    private checkIfSelectionTypeIsYear(): void {
        if (this.selectionType === RbcExportSelectionType.Year) {
            const startYear = +DateUtils.formatEpocToString(
                DateUtils.convertStringToEpoc(DateUtils.startOf(DateUtils.convertEpocToString(this.minCalendarDate), 'year')),
                this.translate.getCurrentLang(),
                'yy'
            );
            const yearDifference = DateUtils.getDateDifference(DateUtils.convertEpocToString(this.minCalendarDate), DateUtils.currentDate, 'year');
            const years = this.createYearArray(startYear, yearDifference);
            this.years$.next(years);
            this.selectedYear = years[0];
            this.onYearChanged(this.selectedYear);
        }
    }

    private createYearArray(startYear: number, yearDifference: number): Array<{ name: string }> {
        const yearsArray = [];

        for (let i = 0; i <= yearDifference; i++) {
            const year = startYear + i;
            yearsArray.push({ name: year.toString() });
        }
        return yearsArray.reverse();
    }
}
