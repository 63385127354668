<section class="header">
    <div class="title-search-actions">
        <div class="title">{{ 'Drill Installations' | translate }}</div>
        <div class="search_area">
            <exg-icon class="search_icon" iconName="search"></exg-icon>

            <exg-textbox
                class="search_input"
                type="text"
                [value]="filter?.term"
                styleType="empty"
                placeholder="{{
                    (currentTab === tabsEnum.DrillInstallations ? 'Search by brand name or brigade number' : 'Search by drilling rig name') | translate
                }}"
                (valueChange)="onValueChange($event)"></exg-textbox>
        </div>
        <div [ngSwitch]="currentTab" class="actions">
            <ng-container *ngSwitchCase="tabsEnum.Table">
                <exg-button class="btn report" [disabled]="pending" styleType="outlined" (btnClick)="onExportClick()">
                    <exg-icon class="btn-icon" iconName="export"></exg-icon>
                    {{ 'Drilling rig report' | translate }}
                </exg-button>
                <exg-button *ngIf="currentUrl !== routes.drillingRig.schedule.fullUrl" [disabled]="readonly" class="btn submit" (btnClick)="addClickButton()">
                    <exg-icon class="btn-icon add" iconName="add_square"></exg-icon>
                    {{ 'Add rig' | translate }}
                </exg-button>
            </ng-container>
            <ng-container *ngSwitchCase="tabsEnum.WorkReport">
                <exg-button [disabled]="true" class="btn submit" (btnClick)="addClickButton()">
                    <exg-icon class="btn-icon add" iconName="export"></exg-icon>
                    {{ 'Create report' | translate }}
                </exg-button>
            </ng-container>
            <ng-container *ngSwitchCase="tabsEnum.DrillInstallations">
                <exg-button [disabled]="true" class="btn submit" (btnClick)="addClickButton()">
                    <exg-icon class="btn-icon add" iconName="export"></exg-icon>
                    {{ 'Download report' | translate }}
                </exg-button>
            </ng-container>
        </div>
    </div>
    <div class="navigation">
        <exg-button class="tab_btn" styleType="custom" [routerLink]="[routes.drillingRig.table.fullUrl]" routerLinkActive="active">
            <exg-icon class="btn-icon tab_btn-icon" iconName="list-check"></exg-icon>
            {{ 'Table' | translate }}
        </exg-button>
        <exg-button *ngIf="isAdmin$ | async" class="tab_btn schedule" styleType="custom" [routerLink]="[routes.drillingRig.schedule.fullUrl]" routerLinkActive="active">
            <exg-icon class="btn-icon tab_btn-icon" iconName="installations"></exg-icon>
            {{ 'Drill Installations' | translate }}
        </exg-button>
        <exg-button class="tab_btn schedule" styleType="custom" [routerLink]="[routes.drillingRig.archive.fullUrl]" routerLinkActive="active">
            <exg-icon class="btn-icon tab_btn-icon" iconName="archive-drilling-rig"></exg-icon>
            {{ 'Archive' | translate }}
        </exg-button>
    </div>
</section>
<exg-dialog [componentData]="componentData"
            [dialogMode]="dialogMode.NoButtons"
            [mobileFullScreen]="true"
            [showDialog]="showDialog"
            (dialogClose)="onDialogClose($event)"></exg-dialog>
