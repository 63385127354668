import { ArchiveEmployeeRequest } from '../../models/business/employee/archive-employee-request.model';
import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';

export class EmployeeArchiveUpdateAction {
    static readonly type = '[Employee Page] UpdateEmployeeArchive';

    constructor(public payload: { uid: string, request: ArchiveEmployeeRequest }) { }
}

export class EmployeeArchiveUpdateSuccessAction {
    static readonly type = '[Employee API] UpdateEmployeeArchive Success';

    constructor(public payload: { uid: string, request: ArchiveEmployeeRequest }) { }
}

export class EmployeeArchiveUpdateFailAction {
    static readonly type = '[Employee API] UpdateEmployeeArchive Fail';

    constructor(public payload: ErrorObject) { }
}

export class EmployeeArchiveUpdateResetAction {
    static readonly type = '[Employee Page] UpdateEmployeeArchive Reset';
}
