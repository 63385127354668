import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { DrillingRigsService } from '../../services/drilling-rigs.service';

import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';

import { DrillingRigUpdateArchiveAction, DrillingRigUpdateArchiveFailAction, DrillingRigUpdateArchiveResetAction, DrillingRigUpdateArchiveSuccessAction } from './drilling-rig-update-archive.actions';

export interface DrillingRigUpdateArchiveStateModel {
    pending: boolean;
    updated: boolean;
    error: ErrorObject;
}

@State<DrillingRigUpdateArchiveStateModel>({
    name: 'drillingRigUpdateArchive',
    defaults: { pending: false, updated: false, error: null }
})
@Injectable()
export class DrillingRigUpdateArchiveState {
    constructor(private drillingRigsService: DrillingRigsService) { }

    @Action(DrillingRigUpdateArchiveAction) async drillingRigUpdate(ctx: StateContext<DrillingRigUpdateArchiveStateModel>, action: DrillingRigUpdateArchiveAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, updated: false, error: null });

        return this.drillingRigsService.archiveDrillingRig(action.payload.uid, action.payload.request)
            .then(() => setTimeout(() => ctx.dispatch(new DrillingRigUpdateArchiveSuccessAction(action.payload)), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new DrillingRigUpdateArchiveFailAction(err)), 0));
    }

    @Action(DrillingRigUpdateArchiveSuccessAction) drillingRigUpdateSuccess(ctx: StateContext<DrillingRigUpdateArchiveStateModel>, _: DrillingRigUpdateArchiveSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, updated: true, error: null });
    }

    @Action(DrillingRigUpdateArchiveFailAction) drillingRigUpdateFail(ctx: StateContext<DrillingRigUpdateArchiveStateModel>, action: DrillingRigUpdateArchiveFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, updated: false, error: action.payload });
    }

    @Action(DrillingRigUpdateArchiveResetAction) drillingRigUpdateReset(ctx: StateContext<DrillingRigUpdateArchiveStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, updated: false, error: null });
    }
}
