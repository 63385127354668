import { Injectable } from '@angular/core';

import { createSelector, Select } from '@ngxs/store';
import { Observable } from 'rxjs';

import { BaseSingletonService } from '../../../burns-ui-framework/shared/services/common/base-singleton.service';

import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';

import { EmployeeArchiveUpdateState, EmployeeArchiveUpdateStateModel } from './employee-update-archive.reducer';

@Injectable({
    providedIn: 'root'
})
export class EmployeeArchiveUpdateSelectors extends BaseSingletonService {

    static readonly getPending = createSelector([EmployeeArchiveUpdateState], (state: EmployeeArchiveUpdateStateModel) => state.pending);

    static readonly getUpdated = createSelector([EmployeeArchiveUpdateState], (state: EmployeeArchiveUpdateStateModel) => state.updated);

    static readonly getError = createSelector([EmployeeArchiveUpdateState], (state: EmployeeArchiveUpdateStateModel) => state.error);

    @Select(EmployeeArchiveUpdateSelectors.getPending) pending$: Observable<boolean>;

    @Select(EmployeeArchiveUpdateSelectors.getUpdated) updated$: Observable<boolean>;

    @Select(EmployeeArchiveUpdateSelectors.getError) error$: Observable<ErrorObject>;

    constructor() {
        super('EmployeeArchiveUpdateSelectors');
    }
}
