import { Injectable } from '@angular/core';

import { Store } from '@ngxs/store';

import { BaseSingletonService } from '../../../burns-ui-framework/shared/services/common/base-singleton.service';

import { DrillingRigWorkbookGetFilterData } from '../../models/filters/drilling-rig-workbook-get-filter.model';

import { DrillingRigWorkbookExportAction, DrillingRigWorkbookExportResetAction } from './drilling-rig-workbook-export.actions';

@Injectable({
    providedIn: 'root'
})
export class DrillingRigWorkbookExportDispatchers extends BaseSingletonService {

    constructor(private store: Store) {
        super('DrillingRigWorkbookExportDispatchers');
    }

    public dispatchDrillingRigWorkbookExportAction(filter: DrillingRigWorkbookGetFilterData) {
        this.store.dispatch(new DrillingRigWorkbookExportAction({ filter }));
    }

    public dispatchDrillingRigWorkbookExportResetAction() {
        this.store.dispatch(new DrillingRigWorkbookExportResetAction());
    }
}
