import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { ShiftsService } from '../../services/shifts.service';

import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';
import { Shift } from '../../models/business/shifts/shift.model';

import { ShiftAction, ShiftFailAction, ShiftResetAction, ShiftSuccessAction } from './shift.actions';

export interface ShiftStateModel {
    entity: Shift;
    pending: boolean;
    error: ErrorObject;
    retrieved: boolean;
}

@State<ShiftStateModel>({
    name: 'shift',
    defaults: { pending: false, entity: null, retrieved: false, error: null }
})
@Injectable()
export class ShiftState {
    constructor(private shiftsService: ShiftsService) {}


    @Action(ShiftAction) shiftGet(ctx: StateContext<ShiftStateModel>, action: ShiftAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, error: null });

        return this.shiftsService.getShift(action.payload.date, action.payload.shiftType, action.payload.workingAreaUid, action.payload.drillingRigUid, action.payload.contractorCompanyUid)
            .then(resp => setTimeout(() => ctx.dispatch(new ShiftSuccessAction(resp)), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new ShiftFailAction(err)), 0));
    }

    @Action(ShiftSuccessAction) shiftGetSuccess(ctx: StateContext<ShiftStateModel>, action: ShiftSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, retrieved: true, entity: action.payload, error: null });
    }

    @Action(ShiftFailAction) shiftGetFail(ctx: StateContext<ShiftStateModel>, action: ShiftFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, retrieved: false, error: action.payload });
    }

    @Action(ShiftResetAction) shiftGetReset(ctx: StateContext<ShiftStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, entity: null, retrieved: false, error: null });
    }
}
