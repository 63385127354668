import { Injectable } from '@angular/core';

import { combineLatest, first } from 'rxjs';

import { BaseSingletonService } from '../../burns-ui-framework/shared/services/common/base-singleton.service';
import { PaginationRequest } from '../../burns-ui-framework/shared/models/common/pagination-request.model';
import { ProfileSelectors } from '../../burns-ui-framework/shared/store/profile/profile.selectors';

import { CompaniesSearchDispatchers } from '../../shared-rbc/store/companies/companies-search.dispatchers';
import { CompaniesSearchSelectors } from '../../shared-rbc/store/companies/companies-search.selectors';
import { DictionaryVehicleCategoriesDispatchers } from '../../shared-rbc/store/dictionaries/dictionary-vehicle-categories.dispatchers';
import { DictionaryVehicleCategoriesSelectors } from '../../shared-rbc/store/dictionaries/dictionary-vehicle-categories.selectors';
import { DictionaryVehicleTypesDispatchers } from '../../shared-rbc/store/dictionaries/dictionary-vehicle-types.dispatchers';
import { DictionaryVehicleTypesSelectors } from '../../shared-rbc/store/dictionaries/dictionary-vehicle-types.selectors';
import { DrillingRigCreateDispatchers } from '../../shared-rbc/store/drilling-rig/drilling-rig-create.dispatchers';
import { DrillingRigCreateSelectors } from '../../shared-rbc/store/drilling-rig/drilling-rig-create.selectors';
import { DrillingRigDeleteDispatchers } from '../../shared-rbc/store/drilling-rig/drilling-rig-delete.dispatchers';
import { DrillingRigDeleteSelectors } from '../../shared-rbc/store/drilling-rig/drilling-rig-delete.selectors';
import { DrillingRigTransfersSelectors } from '../../shared-rbc/store/drilling-rig-transfers/drilling-rig-transfers.selectors';
import { DrillingRigUpdateArchiveDispatchers } from '../../shared-rbc/store/drilling-rig/drilling-rig-update-archive.dispatchers';
import { DrillingRigUpdateArchiveSelectors } from '../../shared-rbc/store/drilling-rig/drilling-rig-update-archive.selectors';
import { DrillingRigUpdateDispatchers } from '../../shared-rbc/store/drilling-rig/drilling-rig-update.dispatchers';
import { DrillingRigUpdateSelectors } from '../../shared-rbc/store/drilling-rig/drilling-rig-update.selectors';
import { DrillingRigWorkbookExportDispatchers } from '../../shared-rbc/store/drilling-rig/drilling-rig-workbook-export.dispatchers';
import { DrillingRigDispatchers } from '../../shared-rbc/store/drilling-rig/drilling-rig.dispatchers';
import { DrillingRigSelectors } from '../../shared-rbc/store/drilling-rig/drilling-rig.selectors';
import { DrillingRigsArchiveDispatchers } from '../../shared-rbc/store/drilling-rig/drilling-rigs-archive.dispatchers';
import { DrillingRigsArchiveSelectors } from '../../shared-rbc/store/drilling-rig/drilling-rigs-archive.selectors';
import { DrillingRigsDispatchers } from '../../shared-rbc/store/drilling-rig/drilling-rigs.dispatchers';
import { DrillingRigsSelectors } from '../../shared-rbc/store/drilling-rig/drilling-rigs.selectors';
import { EmployeesSearchDispatchers } from '../../shared-rbc/store/employee/employees-search.dispatchers';
import { EmployeesSearchSelectors } from '../../shared-rbc/store/employee/employees-search.selectors';
import { SelfEmployeeSelectors } from '../../shared-rbc/store/user-profile/self-employee.selectors';
import { WorkingAreaSearchDispatchers } from '../../shared-rbc/store/working-area/working-area-search.dispatchers';
import { WorkingAreaSearchSelectors } from '../../shared-rbc/store/working-area/working-area-search.selectors';

import { ArchiveDrillingRigRequest } from '../../shared-rbc/models/business/drilling-rig/archive-drilling-rig-request.model';
import { CompaniesListFilterData } from '../../shared-rbc/models/filters/companies-list-filter.model';
import { DrillingRigCreateUpdateRequest } from '../../shared-rbc/models/business/drilling-rig/drilling-rig-create-update-request.model';
import { DrillingRigListGetFilterData } from '../../shared-rbc/models/filters/drilling-rig-list-get-filter.model';
import { DrillingRigWorkbookGetFilterData } from '../../shared-rbc/models/filters/drilling-rig-workbook-get-filter.model';
import { WorkingAreaListGetFilterData } from '../../shared-rbc/models/filters/working-area-list-get-filter.model';

@Injectable({
    providedIn: 'root',
})
export class DrillingRigSandbox extends BaseSingletonService {
    public profile$ = this.profileSelectors.profile$;

    public drillingRigs$ = this.drillingRigsSelectors.drillingRigs$;
    public pending$ = this.drillingRigsSelectors.pending$;
    public error$ = this.drillingRigsSelectors.error$;
    public allDrillingRigsLoaded$ = this.drillingRigsSelectors.allDrillingRigsLoaded$;
    public retrieved$ = this.drillingRigsSelectors.retrieved$;
    public filter$ = this.drillingRigsSelectors.filter$;
    public total$ = this.drillingRigsSelectors.total$;

    public archivedDrillingRigs$ = this.drillingRigsArchiveSelectors.drillingRigs$;
    public archivedPending$ = this.drillingRigsArchiveSelectors.pending$;
    public archivedError$ = this.drillingRigsArchiveSelectors.error$;
    public allArchivedDrillingRigsLoaded$ = this.drillingRigsArchiveSelectors.allDrillingRigsArchiveLoaded$;
    public archivedRetrieved$ = this.drillingRigsArchiveSelectors.retrieved$;
    public archivedFilter$ = this.drillingRigsArchiveSelectors.filter$;
    public archivedTotal$ = this.drillingRigsArchiveSelectors.total$;

    public drillingRig$ = this.drillingRigSelectors.drillingRig$;
    public drillingRigPending$ = this.drillingRigSelectors.pending$;
    public drillingRigError$ = this.drillingRigSelectors.error$;

    public selfEmployee$ = this.selfEmployeeSelectors.employee$;
    public selfEmployeePending$ = this.selfEmployeeSelectors.pending$;
    public selfEmployeeReadonly$ = this.selfEmployeeSelectors.readonly$;
    public selfEmployeeUser$ = this.selfEmployeeSelectors.user$;
    public selfEmployeeError$ = this.selfEmployeeSelectors.error$;

    public created$ = this.drillingRigCreateSelectors.created$;
    public createPending$ = this.drillingRigCreateSelectors.pending$;
    public createError$ = this.drillingRigCreateSelectors.error$;

    public updated$ = this.drillingRigUpdateSelectors.updated$;
    public updatePending$ = this.drillingRigUpdateSelectors.pending$;
    public updateError$ = this.drillingRigUpdateSelectors.error$;

    public deleted$ = this.drillingRigDeleteSelectors.deleted$;
    public deletePending$ = this.drillingRigDeleteSelectors.pending$;
    public deleteError$ = this.drillingRigDeleteSelectors.error$;

    public workingAreasSearch$ = this.workingAreaSearchSelectors.workingAreas$;
    public vehicleCategories$ = this.dictionaryVehicleCategoriesSelectors.vehicleCategories$;
    public vehicleCategoriesPending$ = this.dictionaryVehicleCategoriesSelectors.pending$;
    public vehicleTypes$ = this.dictionaryVehicleTypesSelectors.vehicleTypes$;
    public vehicleTypesPending$ = this.dictionaryVehicleTypesSelectors.pending$;

    public transfers$ = this.drillingRigTransfersSelectors.drillingRigTransfers$;

    public employeeSearch$ = this.employeesSearchSelectors.employee$;
    public employeeSearchPending$ = this.employeesSearchSelectors.pending$;

    public archiveUpdated$ = this.drillingRigUpdateArchiveSelectors.updated$;
    public archiveError$ = this.drillingRigUpdateArchiveSelectors.error$;
    public archivePending$ = this.drillingRigUpdateArchiveSelectors.pending$;

    public companies$ = this.companiesSearchSelectors.companies$;

    constructor(
        private profileSelectors: ProfileSelectors,
        private companiesSearchSelectors: CompaniesSearchSelectors,
        private companiesSearchDispatchers: CompaniesSearchDispatchers,
        private employeesSearchSelectors: EmployeesSearchSelectors,
        private employeesSearchDispatchers: EmployeesSearchDispatchers,
        private selfEmployeeSelectors: SelfEmployeeSelectors,
        private drillingRigsSelectors: DrillingRigsSelectors,
        private drillingRigsDispatchers: DrillingRigsDispatchers,
        private drillingRigsArchiveSelectors: DrillingRigsArchiveSelectors,
        private drillingRigsArchiveDispatchers: DrillingRigsArchiveDispatchers,
        private drillingRigCreateDispatchers: DrillingRigCreateDispatchers,
        private drillingRigCreateSelectors: DrillingRigCreateSelectors,
        private drillingRigDeleteDispatchers: DrillingRigDeleteDispatchers,
        private drillingRigDeleteSelectors: DrillingRigDeleteSelectors,
        private drillingRigUpdateArchiveDispatchers: DrillingRigUpdateArchiveDispatchers,
        private drillingRigUpdateArchiveSelectors: DrillingRigUpdateArchiveSelectors,
        private drillingRigUpdateDispatchers: DrillingRigUpdateDispatchers,
        private drillingRigUpdateSelectors: DrillingRigUpdateSelectors,
        private drillingRigDispatchers: DrillingRigDispatchers,
        private drillingRigSelectors: DrillingRigSelectors,
        private dictionaryVehicleCategoriesDispatchers: DictionaryVehicleCategoriesDispatchers,
        private dictionaryVehicleCategoriesSelectors: DictionaryVehicleCategoriesSelectors,
        private dictionaryVehicleTypesDispatchers: DictionaryVehicleTypesDispatchers,
        private dictionaryVehicleTypesSelectors: DictionaryVehicleTypesSelectors,
        private drillingRigTransfersSelectors: DrillingRigTransfersSelectors,
        private workingAreaSearchDispatchers: WorkingAreaSearchDispatchers,
        private workingAreaSearchSelectors: WorkingAreaSearchSelectors,
        private workbookExportDispatchers: DrillingRigWorkbookExportDispatchers
    ) {
        super('DrillingRigSandbox');
    }

    public dispatchDrillingRigs(request: PaginationRequest) {
        this.drillingRigsDispatchers.dispatchDrillingRigsAction(request);
    }

    public dispatchDrillingRigsReset() {
        this.drillingRigsDispatchers.dispatchDrillingRigsResetAction();
    }

    public dispatchDrillingRigsSetFilter(filterData: DrillingRigListGetFilterData) {
        this.drillingRigsDispatchers.dispatchDrillingRigsSetFilterAction(filterData);
    }

    public dispatchDrillingRigsArchive(request: PaginationRequest) {
        this.drillingRigsArchiveDispatchers.dispatchDrillingRigsArchiveAction(request);
    }

    public dispatchDrillingRigsArchiveReset() {
        this.drillingRigsArchiveDispatchers.dispatchDrillingRigsArchiveResetAction();
    }

    public dispatchDrillingRigsArchiveSetFilter(filterData: DrillingRigListGetFilterData) {
        this.drillingRigsArchiveDispatchers.dispatchDrillingRigsArchiveSetFilterAction(filterData);
    }

    public dispatchDrillingRig(uid: string) {
        this.drillingRigDispatchers.dispatchDrillingRigAction(uid);
    }

    public dispatchDrillingRigReset() {
        this.drillingRigDispatchers.dispatchDrillingRigResetAction();
    }

    public dispatchDrillingRigCreate(request: DrillingRigCreateUpdateRequest) {
        this.drillingRigCreateDispatchers.dispatchDrillingRigCreateAction(request);
    }

    public dispatchDrillingRigCreateReset() {
        this.drillingRigCreateDispatchers.dispatchDrillingRigCreateResetAction();
    }

    public dispatchDrillingRigUpdate(uid: string, request: DrillingRigCreateUpdateRequest) {
        this.drillingRigUpdateDispatchers.dispatchDrillingRigUpdateAction(uid, request);
    }

    public dispatchDrillingRigUpdateReset() {
        this.drillingRigUpdateDispatchers.dispatchDrillingRigUpdateResetAction();
    }

    public dispatchDrillingRigDelete(uid: string) {
        this.drillingRigDeleteDispatchers.dispatchDrillingRigDeleteAction(uid);
    }

    public dispatchDrillingRigDeleteReset() {
        this.drillingRigDeleteDispatchers.dispatchDrillingRigDeleteResetAction();
    }

    public dispatchWorkingAreaSearch(request: WorkingAreaListGetFilterData) {
        this.workingAreaSearchDispatchers.dispatchWorkingAreaSearchAction(request);
    }

    public dispatchWorkingAreaSearchReset() {
        this.workingAreaSearchDispatchers.dispatchWorkingAreaSearchResetAction();
    }

    public dispatchDictionaryVehicleCategories() {
        combineLatest([this.vehicleCategories$, this.vehicleCategoriesPending$])
            .pipe(first())
            .subscribe(res => {
                if (!res[0] && !res[1]) {
                    this.dictionaryVehicleCategoriesDispatchers.dispatchDictionaryVehicleCategoriesAction();
                }
            });
    }

    public dispatchDictionaryVehicleTypes() {
        combineLatest([this.vehicleTypes$, this.vehicleTypesPending$])
            .pipe(first())
            .subscribe(res => {
                if (!res[0] && !res[1]) {
                    this.dictionaryVehicleTypesDispatchers.dispatchDictionaryVehicleTypesAction();
                }
            });
    }

    public dispatchEmployeeSearch() {
        combineLatest([this.employeeSearch$, this.employeeSearchPending$])
            .pipe(first())
            .subscribe(res => {
                if (!res[0] && !res[1]) {
                    this.employeesSearchDispatchers.dispatchEmployeesSearchAction();
                }
            });
    }

    public dispatchCompaniesSearch(filterData: CompaniesListFilterData) {
        this.companiesSearchDispatchers.dispatchCompaniesSearchAction(filterData);
    }

    public dispatchCompaniesSearchReset() {
        this.companiesSearchDispatchers.dispatchCompaniesSearchResetAction();
    }

    public dispatchWorkbookExport(filterData: DrillingRigWorkbookGetFilterData): void {
        this.workbookExportDispatchers.dispatchDrillingRigWorkbookExportAction(filterData);
    }

    public dispatchWorkbookExportReset(): void {
        this.workbookExportDispatchers.dispatchDrillingRigWorkbookExportResetAction();
    }

    public dispatchDrillingRigUpdateArchive(uid: string, request: ArchiveDrillingRigRequest) {
        this.drillingRigUpdateArchiveDispatchers.dispatchDrillingRigUpdateArchiveAction(uid, request);
    }

    public dispatchDrillingRigUpdateArchiveReset() {
        this.drillingRigUpdateArchiveDispatchers.dispatchDrillingRigUpdateArchiveResetAction();
    }
}
