import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { EmployeesService } from '../../services/employees.service';

import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';

import { EmployeeArchiveUpdateAction, EmployeeArchiveUpdateFailAction, EmployeeArchiveUpdateResetAction, EmployeeArchiveUpdateSuccessAction } from './employee-update-archive.actions';

export interface EmployeeArchiveUpdateStateModel {
    pending: boolean;
    updated: boolean;
    error: ErrorObject;
}

@State<EmployeeArchiveUpdateStateModel>({
    name: 'employeeArchiveUpdate',
    defaults: { pending: false, updated: false, error: null }
})
@Injectable()
export class EmployeeArchiveUpdateState {
    constructor(private employeesService: EmployeesService) { }

    @Action(EmployeeArchiveUpdateAction) async employeeUpdate(ctx: StateContext<EmployeeArchiveUpdateStateModel>, action: EmployeeArchiveUpdateAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, updated: false, error: null });

        return this.employeesService.archiveEmployee(action.payload.uid, action.payload.request)
            .then(() => setTimeout(() => ctx.dispatch(new EmployeeArchiveUpdateSuccessAction(action.payload)), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new EmployeeArchiveUpdateFailAction(err)), 0));
    }

    @Action(EmployeeArchiveUpdateSuccessAction) employeeUpdateSuccess(ctx: StateContext<EmployeeArchiveUpdateStateModel>, _: EmployeeArchiveUpdateSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, updated: true, error: null });
    }

    @Action(EmployeeArchiveUpdateFailAction) employeeUpdateFail(ctx: StateContext<EmployeeArchiveUpdateStateModel>, action: EmployeeArchiveUpdateFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, updated: false, error: action.payload });
    }

    @Action(EmployeeArchiveUpdateResetAction) employeeUpdateReset(ctx: StateContext<EmployeeArchiveUpdateStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, updated: false, error: null });
    }
}
