import { Injectable } from '@angular/core';

import { createSelector, Select } from '@ngxs/store';
import { Observable } from 'rxjs';

import { BaseSingletonService } from '../../../burns-ui-framework/shared/services/common/base-singleton.service';

import { EmployeeListGetFilterData } from '../../models/filters/employee-list-get-filter.model';
import { EmployeeListItem } from '../../models/business/employee/employee-list-item.model';
import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';

import { EmployeesArchivedState, EmployeesArchivedStateModel } from './employees-archived.reducer';

@Injectable({
    providedIn: 'root'
})
export class EmployeesArchivedSelectors extends BaseSingletonService {

    static readonly getPending = createSelector([EmployeesArchivedState], (state: EmployeesArchivedStateModel) => state.pending);

    static readonly getEmployeesArchived = createSelector([EmployeesArchivedState], (state: EmployeesArchivedStateModel) => state.uids.map(uid => state.entities[uid]));

    static readonly getRetrieved = createSelector([EmployeesArchivedState], (state: EmployeesArchivedStateModel) => state.retrieved);

    static readonly getError = createSelector([EmployeesArchivedState], (state: EmployeesArchivedStateModel) => state.error);

    static readonly getAllEmployeesArchivedLoaded = createSelector([EmployeesArchivedState], (state: EmployeesArchivedStateModel) => state.allItemsLoaded);

    static readonly getFilter = createSelector([EmployeesArchivedState], (state: EmployeesArchivedStateModel) => state.filter);

    static readonly getTotal = createSelector([EmployeesArchivedState], (state: EmployeesArchivedStateModel) => state.total);

    @Select(EmployeesArchivedSelectors.getPending) pending$: Observable<boolean>;

    @Select(EmployeesArchivedSelectors.getEmployeesArchived) employees$: Observable<EmployeeListItem[]>;

    @Select(EmployeesArchivedSelectors.getRetrieved) retrieved$: Observable<boolean>;

    @Select(EmployeesArchivedSelectors.getError) error$: Observable<ErrorObject>;

    @Select(EmployeesArchivedSelectors.getAllEmployeesArchivedLoaded) allEmployeesLoaded$: Observable<boolean>;

    @Select(EmployeesArchivedSelectors.getFilter) filter$: Observable<EmployeeListGetFilterData>;

    @Select(EmployeesArchivedSelectors.getTotal) total$: Observable<number>;

    constructor() {
        super('EmployeesArchivedSelectors');
    }
}
