import { Injectable } from '@angular/core';

import { createSelector, Select } from '@ngxs/store';
import { Observable } from 'rxjs';

import { BaseSingletonService } from '../../../burns-ui-framework/shared/services/common/base-singleton.service';

import { DrillingRigListGetFilterData } from '../../models/filters/drilling-rig-list-get-filter.model';
import { DrillingRigListItem } from '../../models/business/drilling-rig/drilling-rig-list-item.model';
import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';

import { DrillingRigsArchiveState, DrillingRigsArchiveStateModel } from './drilling-rigs-archive.reducer';

@Injectable({
    providedIn: 'root'
})
export class DrillingRigsArchiveSelectors extends BaseSingletonService {

    static readonly getPending = createSelector([DrillingRigsArchiveState], (state: DrillingRigsArchiveStateModel) => state.pending);

    static readonly getDrillingRigsArchive = createSelector([DrillingRigsArchiveState], (state: DrillingRigsArchiveStateModel) => state.uids.map(uid => state.entities[uid]));

    static readonly getRetrieved = createSelector([DrillingRigsArchiveState], (state: DrillingRigsArchiveStateModel) => state.retrieved);

    static readonly getError = createSelector([DrillingRigsArchiveState], (state: DrillingRigsArchiveStateModel) => state.error);

    static readonly getAllDrillingRigsArchiveLoaded = createSelector([DrillingRigsArchiveState], (state: DrillingRigsArchiveStateModel) => state.allItemsLoaded);

    static readonly getFilter = createSelector([DrillingRigsArchiveState], (state: DrillingRigsArchiveStateModel) => state.filter);

    static readonly getTotal = createSelector([DrillingRigsArchiveState], (state: DrillingRigsArchiveStateModel) => state.total);

    @Select(DrillingRigsArchiveSelectors.getPending) pending$: Observable<boolean>;

    @Select(DrillingRigsArchiveSelectors.getDrillingRigsArchive) drillingRigs$: Observable<DrillingRigListItem[]>;

    @Select(DrillingRigsArchiveSelectors.getRetrieved) retrieved$: Observable<boolean>;

    @Select(DrillingRigsArchiveSelectors.getError) error$: Observable<ErrorObject>;

    @Select(DrillingRigsArchiveSelectors.getAllDrillingRigsArchiveLoaded) allDrillingRigsArchiveLoaded$: Observable<boolean>;

    @Select(DrillingRigsArchiveSelectors.getFilter) filter$: Observable<DrillingRigListGetFilterData>;

    @Select(DrillingRigsArchiveSelectors.getTotal) total$: Observable<number>;

    constructor() {
        super('DrillingRigsArchiveSelectors');
    }
}
