import { EmployeeWorkbookField } from '../business/employee/employee-workbook-field.model';

export class EmployeeWorkbookGetFilter {
    public workingArea?: { uid: string, title: string};
    public dateFrom?: number;
    public dateTo?: number;
    public fields?: EmployeeWorkbookField[];

    constructor(args?: { filterData?: EmployeeWorkbookGetFilterData }) {
        this.workingArea = (args && args.filterData && args.filterData.workingArea != null) ? args.filterData.workingArea : null;
        this.dateFrom = (args && args.filterData && args.filterData.dateFrom != null) ? args.filterData.dateFrom : null;
        this.dateTo = (args && args.filterData && args.filterData.dateTo != null) ? args.filterData.dateTo : null;
        this.fields = (args && args.filterData && args.filterData.fields != null) ? args.filterData.fields : null;
    }
}

export class EmployeeWorkbookGetFilterData {
    public workingArea?: { uid: string, title: string};
    public dateFrom?: number;
    public dateTo?: number;
    public fields?: EmployeeWorkbookField[];
}
