import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { ShiftsService } from '../../services/shifts.service';

import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';
import { WorkingAreaShiftsSummaryListGetFilter } from '../../models/filters/working-area-shifts-summary-list-get-filter.model';

import { ShiftsWorkbookExportGeneralAction, ShiftsWorkbookExportGeneralFailAction, ShiftsWorkbookExportGeneralResetAction, ShiftsWorkbookExportGeneralSuccessAction } from './shifts-workbook-export-general.actions';

export interface ShiftsWorkbookExportGeneralStateModel {
    loaded: boolean;
    pending: boolean;
    error: ErrorObject;
}

@State<ShiftsWorkbookExportGeneralStateModel>({
    name: 'shiftsWorkbookExportGeneral',
    defaults: { pending: false, loaded: false, error: null }
})
@Injectable()
export class ShiftsWorkbookExportGeneralState {
    constructor(private shiftsService: ShiftsService) {}

    @Action(ShiftsWorkbookExportGeneralAction) shiftsWorkbookExportGet(ctx: StateContext<ShiftsWorkbookExportGeneralStateModel>, action: ShiftsWorkbookExportGeneralAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, error: null });

        const filter = new WorkingAreaShiftsSummaryListGetFilter({ filterData: action.payload.filter });
        return this.shiftsService.getShiftsWorkbookGeneral(filter)
            .then(() => setTimeout(() => ctx.dispatch(new ShiftsWorkbookExportGeneralSuccessAction()), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new ShiftsWorkbookExportGeneralFailAction(err)), 0));
    }

    @Action(ShiftsWorkbookExportGeneralSuccessAction) shiftsWorkbookExportGetSuccess(ctx: StateContext<ShiftsWorkbookExportGeneralStateModel>, _: ShiftsWorkbookExportGeneralSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, loaded: true, error: null });
    }

    @Action(ShiftsWorkbookExportGeneralFailAction) shiftsWorkbookExportGetFail(ctx: StateContext<ShiftsWorkbookExportGeneralStateModel>, action: ShiftsWorkbookExportGeneralFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(ShiftsWorkbookExportGeneralResetAction) shiftsWorkbookExportGetReset(ctx: StateContext<ShiftsWorkbookExportGeneralStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, loaded: false, error: null });
    }
}
