import { DrillingRigListGetFilterData } from '../../models/filters/drilling-rig-list-get-filter.model';
import { DrillingRigListItem } from '../../models/business/drilling-rig/drilling-rig-list-item.model';
import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';
import { PaginationRequest } from '../../../burns-ui-framework/shared/models/common/pagination-request.model';
import { PaginationResult } from '../../../burns-ui-framework/shared/models/common/pagination-result.model';

export class DrillingRigsArchiveAction {
    static readonly type = '[DrillingRigsArchive Page] GetDrillingRigsArchive';

    constructor(public payload: PaginationRequest) { }
}

export class DrillingRigsArchiveSuccessAction {
    static readonly type = '[DrillingRigsArchive API] GetDrillingRigsArchive Success';

    constructor(public payload: { list: PaginationResult<DrillingRigListItem>, pagination: PaginationRequest }) { }
}

export class DrillingRigsArchiveFailAction {
    static readonly type = '[DrillingRigsArchive API] GetDrillingRigsArchive Fail';

    constructor(public payload: ErrorObject) { }
}

export class DrillingRigsArchiveResetAction {
    static readonly type = '[DrillingRigsArchive Page] GetDrillingRigsArchive Reset';
}

export class DrillingRigsArchiveSetFilterAction {
    static readonly type = '[DrillingRigsArchive API] SetFilter';

    constructor(public payload: DrillingRigListGetFilterData) { }
}
