import { Injectable } from '@angular/core';

import { BaseSingletonService } from '../../burns-ui-framework/shared/services/common/base-singleton.service';
import { HttpService } from '../../burns-ui-framework/shared/services/common/http.service';

import { CountryShiftsSummaryListGetFilter } from '../models/filters/country-shifts-summary-list-get-filter.model';
import { CountryShiftsSummaryListItem } from '../models/business/shifts/country-shifts-summary-list-item.model';
import { PaginationResult } from '../../burns-ui-framework/shared/models/common/pagination-result.model';
import { ShiftCreateOrUpdateRequest } from '../models/business/shifts/shift-create-or-update-request.model';
import { ShiftType } from '../models/business/shifts/shift-type.enum';
import { Shift } from '../models/business/shifts/shift.model';
import { WorkingAreaShiftsSummaryListGetFilter } from '../models/filters/working-area-shifts-summary-list-get-filter.model';
import { WorkingAreaShiftsSummaryListItem } from '../models/business/shifts/working-area-shifts-summary-list-item.model';

import { ExgTranslateService } from '../../burns-ui-framework/shared/services/common/exg-translate.service';

import { DateUtils } from '../../burns-ui-framework/shared/utils/date-utils';
import { GuidUtils } from '../../burns-ui-framework/shared/utils/guid-utils';
import { String } from '../../burns-ui-framework/shared/utils/string';

@Injectable({
    providedIn: 'root'
})
export class ShiftsService extends BaseSingletonService {

    private settings: {
        service: {
            getShift: string;
            getShifts: string;
            getShiftsCountries: string;
            getShiftsWorkingAreas: string;
            createShift: string;
            getWorkbook: string;
            getWorkbookGeneral: string;
        }
    };

    constructor(private http: HttpService, private translate: ExgTranslateService) {
        super('ShiftsService');
        this.settings = {
            service: {
                getShift: '/drilling/v1/shifts/{0}/{1}/{2}/{3}?contractorCompanyUid={4}',
                getShifts: '/drilling/v1/shifts/working-areas/summary?countryCode={0}&dateFrom={1}&dateTo={2}&sortField={3}&sortOrder={4}&pageIndex={5}&pageSize={6}',
                getShiftsCountries: '/drilling/v1/shifts/countries/common-summary?dateFrom={0}&dateTo={1}&sortField={2}&sortOrder={3}&pageIndex={4}&pageSize={5}',
                getShiftsWorkingAreas: '/drilling/v1/shifts/working-areas/common-summary?countryCode={0}&dateFrom={1}&dateTo={2}&sortField={3}&sortOrder={4}&pageIndex={5}&pageSize={6}',
                createShift: '/drilling/v1/shifts/{0}/{1}/{2}/{3}',
                getWorkbook: '/drilling/v1/shifts/workbook?workingAreaUid={0}&DateFrom={1}&DateTo={2}',
                getWorkbookGeneral: '/drilling/v1/shifts/general-workbook?DateFrom={0}&DateTo={1}'
            }
        };
    }

    public async getShift(date: number, shiftType: ShiftType, workingAreaUid: string, drillingRigUid: string, contractorCompanyUid: string): Promise<Shift> {
        return this.http.get<{ data: Shift }>(String.format(this.settings.service.getShift, drillingRigUid, date, shiftType, workingAreaUid, contractorCompanyUid))
            .then(res => res.data);
    }

    public async getShifts(filter: WorkingAreaShiftsSummaryListGetFilter): Promise<PaginationResult<WorkingAreaShiftsSummaryListItem>> {
        return this.http.get<PaginationResult<WorkingAreaShiftsSummaryListItem>>(String.format(this.settings.service.getShifts, filter.countryCode, filter.dateFrom, filter.dateTo, filter.sortField, filter.sortOrder, filter.pageIndex, filter.pageSize))
            .then((res) => {
                res.pageSize = filter.pageSize;
                res.pageIndex = filter.pageIndex;
                res.list = res.list.map(l => ({ ...l, uid: GuidUtils.newGuid }));
                return res;
            });
    }

    public async getShiftsCountries(filter: CountryShiftsSummaryListGetFilter): Promise<CountryShiftsSummaryListItem[]> {
        return this.http.get<{ list: CountryShiftsSummaryListItem[] }>(String.format(this.settings.service.getShiftsCountries, filter.dateFrom, filter.dateTo, filter.sortField, filter.sortOrder, filter.pageIndex, filter.pageSize))
            .then(res => res.list);
    }

    public async getShiftsWorkingAreas(filter: WorkingAreaShiftsSummaryListGetFilter): Promise<WorkingAreaShiftsSummaryListItem[]> {
        return this.http.get<{ list: WorkingAreaShiftsSummaryListItem[] }>(String.format(this.settings.service.getShiftsWorkingAreas, filter.countryCode, filter.dateFrom, filter.dateTo, filter.sortField, filter.sortOrder, filter.pageIndex, filter.pageSize))
            .then((res) => {
                return res.list.map(l => ({ ...l, uid: GuidUtils.newGuid }));
            });
    }

    public async createShift(date: number, shiftType: ShiftType, workingAreaUid: string, drillingRigUid: string, request: ShiftCreateOrUpdateRequest): Promise<Shift> {
        return this.http.post<{ data: Shift }>(String.format(this.settings.service.createShift, drillingRigUid, date, shiftType, workingAreaUid), request)
            .then(res => res.data);
    }

    public async getShiftsWorkbook(filter: WorkingAreaShiftsSummaryListGetFilter): Promise<Blob> {
        const workbookName = `Справка о выполненных работах по договору - ${filter.workingArea ? filter.workingArea?.title : ''} - ${DateUtils.formatEpocToString(filter.dateFrom, this.translate.getCurrentLang(), 'DD.MM.yy')} - ${DateUtils.formatEpocToString(filter.dateTo, this.translate.getCurrentLang(), 'DD.MM.yy')}.xlsx`;
        return this.http.download(String.format(this.settings.service.getWorkbook, filter.workingArea?.uid, filter.dateFrom, filter.dateTo), workbookName);
    }

    public async getShiftsWorkbookGeneral(filter: WorkingAreaShiftsSummaryListGetFilter): Promise<Blob> {
        const workbookName = `Общая сводка по договору - ${DateUtils.formatEpocToString(filter.dateFrom, this.translate.getCurrentLang(), 'DD.MM.yy')} - ${DateUtils.formatEpocToString(filter.dateTo, this.translate.getCurrentLang(), 'DD.MM.yy')}.xlsx`;
        return this.http.download(String.format(this.settings.service.getWorkbookGeneral, filter.dateFrom, filter.dateTo), workbookName);
    }
}
