import { ArchiveDrillingRigRequest } from '../../models/business/drilling-rig/archive-drilling-rig-request.model';
import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';

export class DrillingRigUpdateArchiveAction {
    static readonly type = '[DrillingRig Page] UpdateDrillingRigArchive';

    constructor(public payload: { uid: string, request: ArchiveDrillingRigRequest }) { }
}

export class DrillingRigUpdateArchiveSuccessAction {
    static readonly type = '[DrillingRig API] UpdateDrillingRigArchive Success';

    constructor(public payload: { uid: string, request: ArchiveDrillingRigRequest }) { }
}

export class DrillingRigUpdateArchiveFailAction {
    static readonly type = '[DrillingRig API] UpdateDrillingRigArchive Fail';

    constructor(public payload: ErrorObject) { }
}

export class DrillingRigUpdateArchiveResetAction {
    static readonly type = '[DrillingRig Page] UpdateDrillingRigArchive Reset';
}
