import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';
import { WorkingAreaShiftsSummaryListGetFilterData } from '../../models/filters/working-area-shifts-summary-list-get-filter.model';

export class ShiftsWorkbookExportGeneralAction {
    static readonly type = '[ShiftsWorkbookExportGeneral Page] GetShiftsWorkbookExportGeneral';

    constructor(public payload: { filter: WorkingAreaShiftsSummaryListGetFilterData }) { }
}

export class ShiftsWorkbookExportGeneralSuccessAction {
    static readonly type = '[ShiftsWorkbookExportGeneral API] GetShiftsWorkbookExportGeneral Success';
}

export class ShiftsWorkbookExportGeneralFailAction {
    static readonly type = '[ShiftsWorkbookExportGeneral API] GetShiftsWorkbookExportGeneral Fail';

    constructor(public payload: ErrorObject) { }
}

export class ShiftsWorkbookExportGeneralResetAction {
    static readonly type = '[ShiftsWorkbookExportGeneral Page] GetShiftsWorkbookExportGeneral Reset';
}
