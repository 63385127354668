import { Injectable } from '@angular/core';

import { Store } from '@ngxs/store';

import { BaseSingletonService } from '../../../burns-ui-framework/shared/services/common/base-singleton.service';

import { ArchiveDrillingRigRequest } from '../../models/business/drilling-rig/archive-drilling-rig-request.model';

import { DrillingRigUpdateArchiveAction, DrillingRigUpdateArchiveResetAction } from './drilling-rig-update-archive.actions';

@Injectable({
    providedIn: 'root'
})
export class DrillingRigUpdateArchiveDispatchers extends BaseSingletonService {

    constructor(private store: Store) {
        super('DrillingRigUpdateArchiveDispatchers');
    }

    public dispatchDrillingRigUpdateArchiveAction(uid: string, request: ArchiveDrillingRigRequest) {
        this.store.dispatch(new DrillingRigUpdateArchiveAction({ uid, request }));
    }

    public dispatchDrillingRigUpdateArchiveResetAction() {
        this.store.dispatch(new DrillingRigUpdateArchiveResetAction());
    }
}
