import { Injectable } from '@angular/core';

import { createSelector, Select } from '@ngxs/store';
import { Observable } from 'rxjs';

import { BaseSingletonService } from '../../../burns-ui-framework/shared/services/common/base-singleton.service';

import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';

import { DrillingRigUpdateArchiveState, DrillingRigUpdateArchiveStateModel } from './drilling-rig-update-archive.reducer';

@Injectable({
    providedIn: 'root'
})
export class DrillingRigUpdateArchiveSelectors extends BaseSingletonService {

    static readonly getPending = createSelector([DrillingRigUpdateArchiveState], (state: DrillingRigUpdateArchiveStateModel) => state.pending);

    static readonly getUpdated = createSelector([DrillingRigUpdateArchiveState], (state: DrillingRigUpdateArchiveStateModel) => state.updated);

    static readonly getError = createSelector([DrillingRigUpdateArchiveState], (state: DrillingRigUpdateArchiveStateModel) => state.error);

    @Select(DrillingRigUpdateArchiveSelectors.getPending) pending$: Observable<boolean>;

    @Select(DrillingRigUpdateArchiveSelectors.getUpdated) updated$: Observable<boolean>;

    @Select(DrillingRigUpdateArchiveSelectors.getError) error$: Observable<ErrorObject>;

    constructor() {
        super('DrillingRigUpdateArchiveSelectors');
    }
}
