import { Injectable } from '@angular/core';

import { Store } from '@ngxs/store';

import { BaseSingletonService } from '../../../burns-ui-framework/shared/services/common/base-singleton.service';

import { DrillingRigListGetFilterData } from '../../models/filters/drilling-rig-list-get-filter.model';
import { PaginationRequest } from '../../../burns-ui-framework/shared/models/common/pagination-request.model';

import { DrillingRigsArchiveAction, DrillingRigsArchiveResetAction, DrillingRigsArchiveSetFilterAction } from './drilling-rigs-archive.actions';

@Injectable({
    providedIn: 'root'
})
export class DrillingRigsArchiveDispatchers extends BaseSingletonService {

    constructor(private store: Store) {
        super('DrillingRigsArchiveDispatchers');
    }

    public dispatchDrillingRigsArchiveAction(request: PaginationRequest) {
        this.store.dispatch(new DrillingRigsArchiveAction(request));
    }

    public dispatchDrillingRigsArchiveResetAction() {
        this.store.dispatch(new DrillingRigsArchiveResetAction());
    }

    public dispatchDrillingRigsArchiveSetFilterAction(filterData: DrillingRigListGetFilterData) {
        this.store.dispatch(new DrillingRigsArchiveSetFilterAction(filterData));
    }
}
