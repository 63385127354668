import { Injectable } from '@angular/core';

import { Store } from '@ngxs/store';

import { BaseSingletonService } from '../../../burns-ui-framework/shared/services/common/base-singleton.service';

import { EmployeeListGetFilterData } from '../../models/filters/employee-list-get-filter.model';
import { PaginationRequest } from '../../../burns-ui-framework/shared/models/common/pagination-request.model';

import { EmployeesArchivedAction, EmployeesArchivedResetAction, EmployeesArchivedSetFilterAction } from './employees-archived.actions';

@Injectable({
    providedIn: 'root'
})
export class EmployeesArchivedDispatchers extends BaseSingletonService {

    constructor(private store: Store) {
        super('EmployeesArchivedDispatchers');
    }

    public dispatchEmployeesArchivedAction(request: PaginationRequest) {
        this.store.dispatch(new EmployeesArchivedAction(request));
    }

    public dispatchEmployeesArchivedResetAction() {
        this.store.dispatch(new EmployeesArchivedResetAction());
    }

    public dispatchEmployeesArchivedSetFilterAction(filterData: EmployeeListGetFilterData) {
        this.store.dispatch(new EmployeesArchivedSetFilterAction(filterData));
    }
}
