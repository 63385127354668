import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { PaginationStateBase, PaginationStateModel } from '../../../burns-ui-framework/shared/store/shared/pagination-state-base';

import { EmployeesService } from '../../services/employees.service';

import { EmployeeListGetFilter, EmployeeListGetFilterData } from '../../models/filters/employee-list-get-filter.model';
import { EmployeeListItem } from '../../models/business/employee/employee-list-item.model';
import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';
import { PaginationRequest } from '../../../burns-ui-framework/shared/models/common/pagination-request.model';

import { EmployeesAction, EmployeesFailAction, EmployeesResetAction, EmployeesSetFilterAction, EmployeesSuccessAction } from './employees.actions';

import { CompareUtils } from '../../../burns-ui-framework/shared/utils/compare-utils';
import { ExgParamsConfig } from '../../exg-params.config';

export interface EmployeesStateModel extends PaginationStateModel<EmployeeListItem> {
    uids: string[];
    entities: { [uid: string]: EmployeeListItem };
    retrieved: boolean;
    allItemsLoaded: boolean;
    index: number;
    pending: boolean;
    error: ErrorObject;
    filter: EmployeeListGetFilterData;
    total: number;
}

@State<EmployeesStateModel>({
    name: 'employees',
    defaults: { pending: false, uids: [], entities: {}, retrieved: false, error: null, allItemsLoaded: false, index: 0, total: 0, filter: {} }
})
@Injectable()
export class EmployeesState extends PaginationStateBase {
    constructor(private employeesService: EmployeesService) {
        super();
    }

    @Action([EmployeesAction]) employeesGet(ctx: StateContext<EmployeesStateModel>, action: EmployeesAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, error: null });

        const filter = new EmployeeListGetFilter({ pageIndex: state.index, pageSize: ExgParamsConfig.pagination.employeesAllListPageSize, filterData: state.filter });
        if (!this.applyFilterPagination(action.payload, state, filter)) {
            ctx.setState({ ...state, pending: false });
            return Promise.resolve();
        }

        return this.employeesService.getEmployees(filter)
            .then(resp => setTimeout(() => ctx.dispatch(new EmployeesSuccessAction({ list: resp, pagination: action.payload })), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new EmployeesFailAction(err)), 0));
    }

    @Action(EmployeesSuccessAction) employeesGetSuccess(ctx: StateContext<EmployeesStateModel>, action: EmployeesSuccessAction) {
        const state = ctx.getState();
        const { uids, entities, allItemsLoaded, index, total } = this.handlePaginationResults(state, action.payload.list, action.payload.pagination, ExgParamsConfig.pagination.employeesAllListPageSize, true);
        ctx.setState({ ...state, pending: false, uids, entities, total, retrieved: true, error: null, allItemsLoaded, index });
    }

    @Action(EmployeesFailAction) employeesGetFail(ctx: StateContext<EmployeesStateModel>, action: EmployeesFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(EmployeesResetAction) employeesGetReset(ctx: StateContext<EmployeesStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, uids: [], entities: {}, retrieved: false, error: null, allItemsLoaded: false, index: 0, filter: {} });
    }

    @Action(EmployeesSetFilterAction) employeesSetFilter(ctx: StateContext<EmployeesStateModel>, action: EmployeesSetFilterAction) {
        const state = ctx.getState();
        const filter = { ...state.filter, ...action.payload };
        if (!CompareUtils.isObjectsEqual(state.filter, filter)) {
            ctx.setState({ ...state, filter, retrieved: false, index: 0 });
            setTimeout(() => ctx.dispatch(new EmployeesAction(PaginationRequest.ReloadCurrentList)), 0);
        }
    }
}
