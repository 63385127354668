import { Injectable } from '@angular/core';

import { Store } from '@ngxs/store';

import { BaseSingletonService } from '../../../burns-ui-framework/shared/services/common/base-singleton.service';

import { ArchiveEmployeeRequest } from '../../models/business/employee/archive-employee-request.model';

import { EmployeeArchiveUpdateAction, EmployeeArchiveUpdateResetAction } from './employee-update-archive.actions';

@Injectable({
    providedIn: 'root'
})
export class EmployeeArchiveUpdateDispatchers extends BaseSingletonService {

    constructor(private store: Store) {
        super('EmployeeArchiveUpdateDispatchers');
    }

    public dispatchEmployeeArchiveUpdateAction(uid: string, request: ArchiveEmployeeRequest) {
        this.store.dispatch(new EmployeeArchiveUpdateAction({ uid, request }));
    }

    public dispatchEmployeeArchiveUpdateResetAction() {
        this.store.dispatch(new EmployeeArchiveUpdateResetAction());
    }
}
